import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subscription, combineLatest, interval } from 'rxjs';
import { tap } from 'rxjs/operators';

import {
  FactorsState,
  getVerifiedFactorWithoutPasscodeStatus,
  mfaTechDifficultiesStatus,
  resetPhoneCodetatus,
  resetVerificationStatus,
  resetVerifiedErrorCount,
} from '@patient-ui/patient-web/store';
import { cleanExactSixDigits } from '@patient-ui/shared/utils';

@Component({
  selector: 'patient-ui-phone-verification',
  templateUrl: './phone-verification.component.html',
  styleUrls: ['./phone-verification.component.scss'],
})
export class PhoneVerificationComponent implements OnInit {
  @Output() submitCode = new EventEmitter<string>();
  @Output() showFactorList = new EventEmitter<void>();
  @Input() verifiedStatus = false;
  @Input() verifiedError = false;
  @Input() verifyErrorCount = 0;
  @Input() isLoading = false;
  @Input() maskedNumber = '';

  phoneCodeStatus$ = this.mfaStore.select(
    getVerifiedFactorWithoutPasscodeStatus
  );
  phoneCode = false;
  mfaTechDifficulties$: Observable<boolean> = this.mfaStore.select(
    mfaTechDifficultiesStatus
  );
  mfaTechDifficultiesStatus = false;
  inputValue = '';
  showInputScreen = false;
  showResendCodeButton = false;
  showBackButton = false;
  showErrorAlert = false;
  timer60SecSubscription!: Subscription;
  timer30SecSubscription!: Subscription;
  clickedBack = false;
  resendCoolDown = false;
  constructor(private mfaStore: Store<FactorsState>) {}

  ngOnInit(): void {
    combineLatest([this.phoneCodeStatus$])
      .pipe(
        tap(([response]) => {
          this.phoneCode = response;
          if (response) {
            this.showInputScreen = true;

            this.timer30SecSubscription = interval(30000).subscribe(() => {
              this.showBackButton = true;
              this.timer30SecSubscription.unsubscribe();
            });
            this.timer60SecSubscription = interval(60000).subscribe(() => {
              this.showResendCodeButton = true;
              this.activateResendCoolDown();
              this.timer60SecSubscription.unsubscribe();
            });
          }
        })
      )
      .subscribe();
    this.mfaTechDifficulties$.subscribe(
      (value) => (this.mfaTechDifficultiesStatus = value)
    );
  }
  /**
   * Get code on the registered phone number
   */
  getCode(screenCode: number) {
    if (this.resendCoolDown) {
      this.showErrorAlert = true;
      return;
    } else {
      this.showErrorAlert = false;
    }
    //ScreenCode: 1: Call from initial screen 2: Call from Resend code button
    if (screenCode === 1) {
      this.showResendCodeButton = false;
      this.showBackButton = false;
      this.submitCode.emit();
      this.inputValue = '';
    } else {
      this.onSubmit();
    }
  }

  activateResendCoolDown() {
    this.resendCoolDown = true;
    setTimeout(() => {
      this.resendCoolDown = false;
    }, 30000);
  }

  /**
   * Handles the submit action for the input.
   */
  onSubmit() {
    if (this.inputValue) {
      this.submitCode.emit(this.inputValue);
    }
  }

  /**
   * Handles the input event from the input field.
   * Sanitizes the value and updates the inputValue property.
   *
   * @param value - The current value of the input field.
   */
  onInput(event: FocusEvent | KeyboardEvent) {
    const val = (event.target as HTMLInputElement).value;
    this.inputValue = cleanExactSixDigits(val);
  }

  backToSendCodeScreen() {
    this.mfaStore.dispatch(resetPhoneCodetatus());
    this.mfaStore.dispatch(resetVerificationStatus());
    this.mfaStore.dispatch(resetVerifiedErrorCount());
    this.showErrorAlert = false;
    this.showInputScreen = false;

    this.clickedBack = true;
    this.resendCoolDown = true;
    setTimeout(() => {
      this.clickedBack = false;
      this.resendCoolDown = false;
    }, 30000);
  }
}
