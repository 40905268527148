import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import {
  AuthService,
  AuthState,
  PatientState,
  selectAuthState,
  selectLoadLoggedInPatientRequestStatus,
} from '@patient-ui/patient-web/store';
import { RequestStatus } from '@patient-ui/shared/enums';

@Injectable({
  providedIn: 'root',
})
export class AnonymousRequiredGuard implements CanActivate {
  authLoggedIn = new BehaviorSubject(false);
  patientLoggedIn = new BehaviorSubject(false);
  destroyed = new Subject();

  constructor(
    private patientStore: Store<PatientState>,
    private authStore: Store<AuthState>,
    private router: Router,
    private authService: AuthService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const onDemandRedirect = route.queryParams['redirecturl'];
    return new Promise((resolve) => {
      this.setAuthState();

      // Check if logged in and all values acquired
      if (this.authLoggedIn.getValue() && this.patientLoggedIn.getValue()) {
        if (onDemandRedirect) {
          window.location.href = onDemandRedirect;
        } else {
          this.router.navigate(['/portal/dashboard']);
        }
        resolve(false);
      } else if (
        !this.authLoggedIn.getValue() ||
        !this.patientLoggedIn.getValue()
      ) {
        // Check if session exists
        this.authService.oktaAuth.session?.get().then(async (sess) => {
          if (
            sess.status === 'ACTIVE' &&
            !this.authService.oktaAuth.isLoginRedirect()
          ) {
            await this.authService.handleExistingSession(
              sess,
              onDemandRedirect || '/portal/dashboard'
            );

            this.setAuthState();
            this.authService.isAuthenticated$.subscribe((isAuth) => {
              if (isAuth) {
                this.router.navigate(['/portal/dashboard']);
                resolve(false);
              } else {
                resolve(true);
              }
            });
          } else {
            resolve(true);
          }
        });
      } else {
        resolve(true);
      }
    });
  }

  setAuthState(): void {
    this.authStore
      .select(selectAuthState)
      .subscribe((state) => this.authLoggedIn.next(state.loggedIn));

    this.patientStore
      .select(selectLoadLoggedInPatientRequestStatus)
      .subscribe((status) =>
        status === RequestStatus.Success
          ? this.patientLoggedIn.next(true)
          : this.patientLoggedIn.next(false)
      );
  }
}
