<ng-container *ngIf="showInstruction">
  <div class="modal-body pb-3">
    <div class="custom-modal-body">
      <h4>Install the Okta Verify mobile app</h4>
      <div class="row pl-4 py-2">
        Okta Verify is a mobile security app used to verify your identity when you sign in to Labcorp Patient.
      </div>
      <div class="row py-6 fix-mobile-py">
        <div class="col-sm-6">
          Search "Okta Verify" in the App Store or Google Play Store.
        </div>
        <div class="col-sm-6 pl-8 fix-p-mobile">
          <ul class="list-inline mb-2 fix-mobile">
            <li class="list-inline-item mr-4">
              <a href="https://apps.apple.com/us/app/okta-verify/id490179405" target="_blank"
                ><img alt="Apple App Store" class="img-fluid h-42" src="assets/images/apple-app-store.svg"
              /></a>
            </li>
            <li class="list-inline-item">
              <a href="https://play.google.com/store/apps/details?id=com.okta.android.auth&hl=en_US&gl=US" target="_blank"
                ><img alt="Google Play" class="img-fluid h-42" src="assets/images/google-play.svg"
              /></a>
            </li>
          </ul>
        </div>
      </div>
      <p>Once you've installed the app, click <strong>Next.</strong></p>

      <p>
        <strong>Note: </strong>The Okta Verify app requires you to scan a QR code in Labcorp Patient with your mobile device. You'll need to
        turn on MFA from a desktop, laptop or tablet so that you can scan the code from Okta Verify on your mobile device.
      </p>
      <p>Emails regarding Okta Verify will come from <a href="mailto:noreply@okta.com">noreply&#64;okta.com</a>.</p>
    </div>
    <ui-legal-disclaimer
      linkText="Next"
      termsUrl="https://www.labcorp.com/terms-and-conditions"
      privacyUrl="https://www.labcorp.com/hipaa-privacy"
    ></ui-legal-disclaimer>
  </div>
  <div class="modal-footer justify-content-end">
    <button id="nextOktaInitial" type="button" class="btn btn-primary" [disabled]="isLoading" (click)="onNext('QRC')">
      Next
    </button>
  </div>
</ng-container>

<ng-container *ngIf="showQRCode">
  <div class="modal-body pb-3">
    <div class="custom-modal-body">
      <h3>Activate Okta Verify</h3>
      <div class="row">
        <div class="col-md-8 border-right">
          <p>Step 1: Open Okta Verify on your mobile device.</p>
          <p>Step 2: Tap <strong>Account.</strong></p>
          <p>Step 3: Respond to prompts to allow camera access and push notifications.</p>
          <p>Step 4: Point the mobile device camera at the QR code on your computer screen.</p>
          <p>The page will automatically move to the next step once enrollment is complete.</p>
        </div>
        <div class="col-md-4 text-center container-no-padding">
          <img class="qr-code" src="{{ oktaQRCodeURL }}" />
          <p *ngIf="oktaQRCodeURL" class="text-danger">QR code expires in {{ timerValue }} seconds</p>
          <ng-container *ngIf="!oktaQRCodeURL">
            <div class="spinner-border text-gray" role="status"></div>
            <p class="small text-gray">Loading...</p>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer justify-content-end">
    <button id="cancelOktaEnrollment" type="button" class="btn btn-primary" (click)="cancel()">Cancel</button>
  </div>
</ng-container>

<ng-container *ngIf="showConfirmationScreen">
  <patient-ui-confirmation-screen factorType="Okta Verify"></patient-ui-confirmation-screen>
</ng-container>
