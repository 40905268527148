import { Component, Input } from '@angular/core';

@Component({
  selector: 'ui-legal-disclaimer',
  templateUrl: './legal-disclaimer.component.html',
  styleUrls: ['./legal-disclaimer.component.scss'],
})
export class LegalDisclaimerComponent {
  @Input() addClass = 'text-dark';
  @Input() linkText = 'Link Account';
  @Input() termsUrl = 'https://www.labcorp.com/terms-and-conditions';
  @Input() privacyUrl = 'https://www.labcorp.com/hipaa-privacy';
}
