<ng-container *ngIf="showInstruction">
  <div class="modal-body pb-3">
    <div class="custom-modal-body">
      <h4>Install the Google Authenticator mobile app</h4>
      <div class="row pl-4 py-2">
        Google Authenticator is a mobile security app used to verify your identity when you sign in to Labcorp Patient.
      </div>
      <div class="row py-6 fix-mobile-py">
        <div class="col-sm-6">
          Search 'Google Authenticator' in App Store or Google Play Store.
        </div>
        <div class="col-sm-6 pl-8 fix-p-mobile">
          <ul class="list-inline mb-2 fix-mobile">
            <li class="list-inline-item mr-4">
              <a href="https://apps.apple.com/au/app/google-authenticator/id388497605" target="_blank"
                ><img alt="Apple App Store" class="img-fluid h-42" src="assets/images/apple-app-store.svg"
              /></a>
            </li>
            <li class="list-inline-item">
              <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en_US&gl=US" target="_blank"
                ><img alt="Google Play" class="img-fluid h-42" src="assets/images/google-play.svg"
              /></a>
            </li>
          </ul>
        </div>
      </div>
      <p>Once you've installed the app, click <strong>Next.</strong></p>
    </div>
  </div>
  <div class="modal-footer justify-content-end">
    <button id="nextGoogleInitial" type="button" class="btn btn-primary" [disabled]="isLoading" (click)="onNext('QRC')">
      Next
    </button>
  </div>
</ng-container>

<ng-container *ngIf="showQRCode">
  <div class="modal-body pb-3">
    <div class="custom-modal-body">
      <h3>Activate Google Authenticator</h3>
      <div class="row">
        <div class="col-md-8 border-right">
          <p>Step 1: Open Google Authenticator on your mobile device.</p>
          <p>Step 2: Tap <strong>OK</strong> when prompted to access camera.</p>
          <p>Step 3: Tap <strong>Begin setup</strong>.</p>
          <p>Step 4: Tap <strong>Scan barcode</strong>.</p>
          <p>Step 5: Point the mobile device camera at the QR code on your computer screen.</p>
          <p>When Google Authenticator is configured, click <strong>Next.</strong></p>
        </div>
        <div class="col-md-4 text-center">
          <ng-container *ngIf="!oktaQRCodeURL">
            <div class="spinner-border text-gray" role="status"></div>
            <p class="small text-gray">Loading...</p>
          </ng-container>
          <div class="col-md-12 container-no-padding">
            <img class="qr-code" src="{{ oktaQRCodeURL }}" />
          </div>
          <div class="col-md-12 container-no-padding">
            <button id="contScanGoogleQR" type="button" class="btn btn-link" (click)="showManualEnrollScreen()">Unable to scan?</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer justify-content-end">
    <button id="backGoogleEnrollment" type="button" class="btn btn-secondary" (click)="back('INSTRUCTION')">Back</button>
    <button id="nextGoogleQRC" type="button" class="btn btn-primary" (click)="onNext('OTP')">Next</button>
  </div>
</ng-container>

<ng-container *ngIf="showManualEnrollInstruction">
  <div class="modal-body pb-3">
    <div class="custom-modal-body">
      <h3>Manually Activate Google Authenticator</h3>
      <div class="row">
        <div class="col-md-12">
          <p>Step 1: Open Google Authenticator on your mobile device.</p>
          <p>Step 2: Tap the <strong>+</strong> icon.</p>
          <p>Step 3: Tap <strong>Enter a setup key</strong>.</p>
          <p>Step 4: Enter your account and key as shown.</p>
          <p class="ml-7">Account: {{ userEmail }}</p>
          <p class="ml-7">Key (case sensitive): {{ sharedSecret }}</p>
          <p>Step 5: Tap <strong>Add</strong> to save.</p>
          <p>When Google Authenticator is configured, click <strong>Next</strong>.</p>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer justify-content-end">
    <button id="backGoogleEnrollment" type="button" class="btn btn-secondary w-md-100" (click)="back('QRC')">Back</button>
    <button id="nextGoogleQRC" type="button" class="btn btn-primary w-md-100" (click)="onNext('OTP')">Next</button>
  </div>
</ng-container>

<ng-container *ngIf="showOTPForm">
  <div class="modal-body pb-3">
    <div class="custom-modal-body text-center mb-2">
      <p>
        {{ invalidInput ? 'Re-enter' : 'Enter' }} the six-digit code displayed on your mobile device and click
        <strong>{{ invalidInput ? 'Retry' : 'Activate' }}</strong>
      </p>
      <patient-ui-activate-form
        name="fieldName"
        ngDefaultControl
        #input="ngModel"
        [(ngModel)]="data"
        [invalidInput]="invalidInput"
      ></patient-ui-activate-form>
      <small class="form-text invalid-feedback" [ngClass]="invalidInput ? 'd-block' : 'd-none'">
        <i class="material-icons icon-sm">error_outline</i>
        <span class="align-self-center pl-1" [innerHTML]="'The verification code you entered is incorrect'"></span>
      </small>
    </div>

    <ui-legal-disclaimer
      linkText="Activate"
      termsUrl="https://www.labcorp.com/terms-and-conditions"
      privacyUrl="https://www.labcorp.com/hipaa-privacy"
    ></ui-legal-disclaimer>
  </div>
  <div class="modal-footer justify-content-center">
    <button
      id="activateGoogleEnrollment"
      type="button"
      class="btn btn-primary"
      [disabled]="!input.valid || isLoading"
      (click)="activateGoogleFactor(data)"
    >
      {{ invalidInput ? 'Retry' : 'Activate' }}
    </button>
  </div>
</ng-container>

<ng-container *ngIf="showConfirmationScreen">
  <patient-ui-confirmation-screen factorType="Google Authenticator"></patient-ui-confirmation-screen>
</ng-container>
