import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Subject } from 'rxjs';

import { FORM_ERROR_MESSAGES } from '@patient-ui/shared/constants';
import { EnvironmentService } from '@patient-ui/shared-ui/utils';

import { PdfViewerService } from '../services/pdf-viewer.service';
import { dateValidator } from '../validators/date.validator';

@Component({
  selector: 'patient-ui-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.scss'],
})
export class PdfViewerComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('pdfViewerOnLoad')
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  pdfViewerOnLoad!: { pdfSrc: any; refresh: () => void };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @ViewChild('securityModalRef') securityModalRef!: any;
  @ViewChild('techIssueModalRef', { static: true })
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  techIssueModalRef!: any;

  @ViewChild('ieNotificationModalRef', { static: true })
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ieNotificationModalRef!: any;
  isIe = false;

  destroyed = new Subject();
  documentId!: string;
  documentType!: string;
  isTechDifficulties$ = new BehaviorSubject<boolean>(false);
  showAlert$ = new BehaviorSubject<boolean>(false);
  dobForm!: FormGroup;
  decryptionRequired = false;
  uuid!: string;

  readonly validationMessages = {
    dateOfBirth: {
      required: FORM_ERROR_MESSAGES.common.required,
      pattern: FORM_ERROR_MESSAGES.date.pattern,
      invalidDate: FORM_ERROR_MESSAGES.date.invalidDate,
      mask: FORM_ERROR_MESSAGES.date.mask,
    },
  };
  maxDate = new Date();
  minDate = new Date();
  btnPdfFormSubmitSpin = false;
  showPdfModal = true;

  private patientUrl = '';

  constructor(
    private builder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private patientPdfService: PdfViewerService,
    private modalService: NgbModal,
    private router: Router,
    private envService: EnvironmentService
  ) {}

  ngOnInit(): void {
    this.patientUrl = this.envService.patientUrl;
    this.isIe = this.msIeVersion();

    if (this.isIe) {
      return;
    }

    this.dobForm = this.builder.group({
      dateOfBirth: [
        '',
        [Validators.required, dateValidator(this.maxDate, this.minDate)],
      ],
    });
    this.maxDate.setFullYear(this.maxDate.getFullYear());
    this.minDate.setFullYear(this.minDate.getFullYear() - 120);

    this.activatedRoute?.paramMap?.subscribe((params) => {
      this.documentType = params.get('docType') || '';
    });

    this.activatedRoute?.queryParamMap?.subscribe((params) => {
      this.documentId = params.get('transaction') || '';
    });

    if (this.documentId) {
      this.documentId = this.documentId.replace(/ /g, `+`);
    }
    const portalUserFlag = localStorage.getItem(`portalUser`);
    const sessionFlag = localStorage.getItem('pyrptid');

    if (portalUserFlag) {
      localStorage.removeItem(`portalUser`);
    }

    if (sessionFlag) {
      this.showPdfModal = false;
      this.loadPdf('', !!portalUserFlag);
      localStorage.removeItem('pyrptid');
    }

    this.activatedRoute?.queryParamMap?.subscribe((params) => {
      this.decryptionRequired =
        params.get('decryptionRequired') === 'true' || false;
      this.uuid = params.get('uuid') || '';
    });

    if (window.sessionStorage.getItem(this.uuid)) {
      const reqObject = JSON.parse(sessionStorage.getItem(this.uuid) || '{}');

      this.documentId = reqObject.transIdList.join(',');
      this.showPdfModal = reqObject.showPdfModal;
      if (!this.showPdfModal) {
        this.loadCombinedInvoicePdfDetails(
          reqObject.transIdList,
          !!portalUserFlag,
          this.decryptionRequired,
          ''
        );
      }
      reqObject.showPdfModal = true;
      window.sessionStorage.setItem(this.uuid, JSON.stringify(reqObject));
    }
  }

  showProcessing(isProcessing: boolean, elemId?: string): void {
    if (!isProcessing) {
      this.btnPdfFormSubmitSpin = false;
    } else {
      this.btnPdfFormSubmitSpin = elemId === 'btnPdfFormSubmit';
    }
  }

  ngAfterViewInit(): void {
    if (!this.isIe && this.showPdfModal) {
      this.modalService.dismissAll();
      this.modalService.open(this.securityModalRef, {
        size: 'md',
        backdrop: 'static',
        keyboard: false,
      });
    }
  }

  open() {
    return this.securityModalRef.open();
  }

  handleSubmit(): void {
    this.showProcessing(true, 'btnPdfFormSubmit');
    if (this.documentId && this.documentType) {
      const dateOfBirthFormatted = this.formatDate(
        this.dobForm.get('dateOfBirth')?.value
      );
      if (this.router.url.includes('/rendermultiplepdf/invoice') === true) {
        this.loadCombinedInvoicePdfDetails(
          this.documentId.split(','),
          false,
          this.decryptionRequired,
          dateOfBirthFormatted
        );
      } else {
        this.loadPdf(dateOfBirthFormatted, false);
      }
    } else {
      this.showProcessing(false, `btnPdfFormSubmit`);
    }
  }

  loadPdf(dob: string, portalUser: boolean): void {
    this.patientPdfService
      .getInvoicePdfDetails(this.documentId, this.documentType, dob, portalUser)
      .subscribe(
        (res: Blob) => {
          this.pdfViewerOnLoad.pdfSrc = res;
          this.pdfViewerOnLoad.refresh();
          this.modalService.dismissAll();
        },
        (err) => {
          if (err.status === 404 || err.status === 400) {
            this.showAlert$.next(true);
            this.isTechDifficulties$.next(false);
            window.scrollTo(0, 0);
          } else {
            this.showAlert$.next(false);
            this.isTechDifficulties$.next(true);
            if (!this.isIe && !this.showPdfModal) {
              this.modalService.dismissAll();
              this.modalService.open(this.techIssueModalRef, {
                size: 'md',
                backdrop: 'static',
                keyboard: false,
              });
            }
          }
          this.showProcessing(false);
        }
      );
  }

  handleCancel(): void {
    this.modalService.dismissAll();
    this.router.navigate(
      ['/externalRedirect', { externalUrl: this.patientUrl }],
      {
        skipLocationChange: true,
      }
    );
  }

  get formControls() {
    return this.dobForm.controls;
  }

  ngOnDestroy(): void {
    localStorage.removeItem(this.uuid);
    this.destroyed.next();
    this.destroyed.complete();
  }

  private formatDate(dateString: string) {
    return dateString
      ? dateString.substring(4) +
          '-' +
          dateString.substring(0, 2) +
          '-' +
          dateString.substring(2, 4)
      : '';
  }

  private msIeVersion(): boolean {
    const ua = window.navigator.userAgent;
    const msie = ua.indexOf('MSIE ');

    // eslint-disable-next-line no-useless-escape
    if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
      // If Internet Explorer, return version number
      this.openNotificationModal();
      return true;
    } // If another browser, return 0
    else {
      return false;
    }
  }

  private openNotificationModal() {
    return this.modalService.open(this.ieNotificationModalRef, {
      backdrop: 'static',
      keyboard: false,
    });
  }

  loadCombinedInvoicePdfDetails(
    transIdList: string[],
    portalUser: boolean,
    encryptedIds: boolean,
    dob: string
  ): void {
    this.patientPdfService
      .getCombinedInvoicePdfDetails(
        transIdList,
        this.documentType,
        portalUser,
        encryptedIds,
        dob
      )
      .subscribe(
        (res: Blob) => {
          this.pdfViewerOnLoad.pdfSrc = res;
          this.pdfViewerOnLoad.refresh();
          this.modalService.dismissAll();
        },
        (err) => {
          if (err.status === 404 || err.status === 400) {
            this.showAlert$.next(true);
            this.isTechDifficulties$.next(false);
          } else {
            this.showAlert$.next(false);
            this.isTechDifficulties$.next(true);
          }
          this.showProcessing(false);
        }
      );
  }
}
