<footer>
  <div class="bg-navy py-4">
    <div class="container-xxl text-white">
      <div class="col-12">
        <div class="d-flex flex-wrap justify-content-center text-center">
          <ul class="list-inline mb-5">
            <li class="list-inline-item mx-2 mb-2">
              <a href="https://www.labcorp.com/terms-and-conditions" target="_blank" class="text-white">Terms and Conditions</a>
            </li>
            <li class="list-inline-item mx-2 mb-2">•</li>
            <li class="list-inline-item mx-2 mb-2">
              <a href="https://www.labcorp.com/hipaa-privacy" target="_blank" class="text-white">Privacy Policy</a>
            </li>
            <li class="list-inline-item mx-2 mb-2">•</li>
            <li class="list-inline-item mx-2 mb-2">
              <a [href]="patientUserAgreement" target="_blank" class="text-white">Patient Portal User Agreement</a>
            </li>
            <li class="list-inline-item mx-2 mb-2">•</li>
            <li class="list-inline-item mx-2 mb-2">
              <a class="ot-sdk-show-settings text-white" tabindex="0" onkeydown="if (event.key === 'Enter') this.click()"
                >Do Not Sell or Share My Personal Information</a
              >
            </li>
            <li class="list-inline-item mx-2 mb-2">•</li>
            <li class="list-inline-item mx-2 mb-2">
              <a href="https://www.labcorp.com/patients/bill-pay/payment-options/no-surprises-act" target="_blank" class="text-white"
                >No Surprise Act</a
              >
            </li>
            <li class="list-inline-item mx-2 mb-2">•</li>
            <li class="list-inline-item mx-2 mb-2">
              <a href="https://www.labcorp.com/labcorp-nondiscrimination-notice" target="_blank" class="text-white"
                >Notice of Nondiscrimination</a
              >
            </li>
          </ul>
        </div>
        <div class="d-flex flex-wrap justify-content-center text-center">
          <small> Labcorp Patient™ © {{ appYear }} Laboratory Corporation of America® Holdings. All Rights Reserved. </small>
        </div>
      </div>
    </div>
  </div>
</footer>
