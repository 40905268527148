import { HttpClient } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subject, Subscription, throwError, timer } from 'rxjs';
import {
  catchError,
  delay,
  distinctUntilChanged,
  filter,
  switchMap,
  take,
  takeUntil,
  takeWhile,
} from 'rxjs/operators';

import {
  FactorsState,
  getOktaTransactionId,
  mfaTechDifficultiesStatus,
  oktaPushNotVerified,
  oktaPushVerified,
} from '@patient-ui/patient-web/store';
import { ActivateFactorResponse } from '@patient-ui/shared/models';
import { EnvironmentService } from '@patient-ui/shared-ui/utils';

@Component({
  selector: 'patient-ui-okta-push-verification',
  templateUrl: './okta-push-verification.component.html',
  styleUrls: ['./okta-push-verification.component.scss'],
})
export class OktaPushVerificationComponent implements OnInit, OnDestroy {
  @Output() submitCode = new EventEmitter<string>();
  @Output() showFactorList = new EventEmitter<void>();
  @Input() verifiedStatus = false;
  @Input() verifiedError = false;
  @Input() verifyErrorCount = 0;
  @Input() isLoading = false;
  @Input() factorID = '';
  @Input() oktaID = '';

  transactionId$ = this.mfaStore.select(getOktaTransactionId);
  oktaTransactionId = '';
  mfaTechDifficulties$: Observable<boolean> = this.mfaStore.select(
    mfaTechDifficultiesStatus
  );
  mfaTechDifficultiesStatus = false;
  showPushNotificationScreen = false;
  timerValue = 90;

  private unsubscribe$ = new Subject<void>();
  private sourceSubscription: Subscription | undefined;
  private countdownTimerSubscription: Subscription | undefined;
  private destroy$ = new Subject<void>();
  constructor(
    private http: HttpClient,
    private envService: EnvironmentService,
    private mfaStore: Store<FactorsState>
  ) {}

  ngOnInit(): void {
    this.submitCode
      .asObservable()
      .pipe(
        switchMap(() =>
          this.transactionId$.pipe(
            filter((transactionId) => !!transactionId), // Filter out empty values
            distinctUntilChanged(), // Prevent multiple emissions for the same value
            delay(100),
            take(1),
            takeUntil(this.destroy$)
          )
        )
      )
      .subscribe((transactionId) => {
        this.oktaTransactionId = transactionId || '';
        this.startTimer();
        this.showPushNotificationScreen = true;
      });
    this.mfaTechDifficulties$.subscribe(
      (value) => (this.mfaTechDifficultiesStatus = value)
    );
  }

  /**
   * Get push notification on registered phone
   */
  getCode() {
    this.submitCode.emit();
  }

  ngOnDestroy() {
    if (this.sourceSubscription) {
      this.sourceSubscription.unsubscribe();
    }
    if (this.countdownTimerSubscription) {
      this.countdownTimerSubscription.unsubscribe();
    }
  }

  startTimer() {
    const countdownTimer$ = timer(0, 1000);
    this.countdownTimerSubscription = countdownTimer$.subscribe(() => {
      if (this.timerValue > 0) {
        this.timerValue--;
        if (this.timerValue === 0 && !this.verifiedStatus) {
          this.mfaStore.dispatch(oktaPushNotVerified());
        }
      }
    });
    const source$ = countdownTimer$.pipe(
      takeUntil(this.unsubscribe$),
      takeWhile(() => this.timerValue > 0),
      switchMap(() => {
        if (this.timerValue % 4 === 0) {
          this.oktaActivatePoll();
        }
        return timer(0);
      }),
      catchError((error) => throwError(error))
    );
    this.sourceSubscription = source$.subscribe(() => {});
  }

  showInitialScreen() {
    this.unsubscribeTimer();
    this.showPushNotificationScreen = false;
  }

  oktaActivatePoll() {
    const url =
      this.envService.baseUrl +
      '/guest/patients/mfa/user/' +
      this.oktaID +
      `/factor/` +
      this.factorID +
      `/transaction/` +
      this.oktaTransactionId;
    return this.http.get<ActivateFactorResponse>(url).subscribe({
      next: (response) => {
        if (response.factorResult === 'SUCCESS') {
          this.unsubscribeTimer();
          return this.mfaStore.dispatch(oktaPushVerified());
        }
      },
    });
  }

  unsubscribeTimer() {
    if (this.countdownTimerSubscription) {
      this.countdownTimerSubscription.unsubscribe();
    }
    if (this.sourceSubscription) {
      this.sourceSubscription.unsubscribe();
    }
  }
}
